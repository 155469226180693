import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {RestApiService} from "../rest-api.service";
import {AlertService} from "../alert.service";

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.css']
})
export class ResetPasswordPageComponent implements OnInit {

    token: string;
    user_id:number;

    model:any;

    isValidToken:boolean;

    resetFinish:boolean;


  constructor(private route: ActivatedRoute, private rest: RestApiService, public alertService: AlertService) {

      this.model = {
          password : '',
          confirmPassword:''
      };
      this.isValidToken = false;
      this.resetFinish = false;

     this.route.queryParams
          .subscribe(params => {
              this.token = params['token'];

              this.rest.confirmToken(this.token).subscribe((data: any) => {


                  if(data.success){
                      this.user_id = data.message;
                      this.isValidToken = true;
                  } else {
                      this.isValidToken = false;
                      this.alertService.error(data.message);
                  }
              });
          });
  }

  ngOnInit() {
  }

  submit(){

      console.log(this.user_id, this.model);

      if (this.model.password === this.model.confirmPassword && (this.model.password !== '' && this.model.confirmPassword !== '')) {

          let obj = {
              user_id: this.user_id,
              password: this.model.password
          };

          this.rest.newPassword(obj).subscribe( (data: any) =>{

              const response = JSON.parse(data._body);

              if(response.success){
                  this.alertService.success(response.message);
                  this.resetFinish = true;
              } else {
                  this.alertService.error(response.message);
              }
          });

      } else {
          this.alertService.error('Password must be the same!');
      }

  }

}
