import { Component, OnInit } from '@angular/core';
import { RestApiService } from '../rest-api.service';
import { PubSearchFormComponent } from '../pub-search-form/pub-search-form.component'

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.css']
})
export class PublicationsComponent implements OnInit {

    listPublications :any = [];
    keys :any = [];
  constructor( private RestApiService :RestApiService) { }


  ngOnInit() {
      this.RestApiService.getPublicationsAPI().subscribe(res =>{
          this.listPublications = res;

          for(let key in res){
              this.keys.push({key: key, value: res[key] });
          }

          this.keys.sort(function (name1, name2) {
              if (name1.key > name2.key) {
                  return -1;
              } else if (name1.key < name2.key) {
                  return 1;
              } else {
                  return 0;
              }
          });


      })
  }


}
