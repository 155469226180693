import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute,Params } from '@angular/router';
import{ AlertService} from "../alert.service";
import { AuthenticateService } from "../authenticate.service";
import {User} from "../user";


@Component({
  selector: 'app-t-download',
  templateUrl: './t-download.component.html',
  styleUrls: ['./t-download.component.css']
})
export class TDownloadComponent implements OnInit {
    tool: String = '';
    part: string = '';
    model: any = {};
    modelReg: any = {};
    loading = false;
    returnUrl: string;
    successReg:boolean;
    errorTXT:boolean;
    alerTextUserReg: string;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticateService,
      private alertService: AlertService
  ) {

      this.route.params.subscribe((params: Params) => {
          this.tool = params['tool'];
          this.part = params['section'];
      });
  }

  ngOnInit() {
      // reset login status
      this.authenticationService.logout();
      this.authenticationService.isLogin = false;

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'research/'+this.tool+'/home/download';

  }

    login() {
        this.loading = true;

        this.authenticationService.login(this.model)
            .subscribe(
                data => {

                    if(data.success == true ) {
                        console.log('success');
                        this.router.navigate([this.returnUrl]);
                        this.loading = false;
                    } else {
                        const messages = data.message;
                        this.alertService.error(messages);
                        this.loading = false;
                    }
                },
                error => {
                  console.log(error);
                    const messages = JSON.parse(error._body);
                    this.alertService.error(messages.message);
                    this.loading = false;
                });
    }

    @ViewChild('regForm') regForm;
    registration() {
        //this.loading = true;
        let valueValidatorEmpty = [];


        if (this.regForm.form.controls.nome.valid === false)
            valueValidatorEmpty.push('Name');
        if (this.regForm.form.controls.cognome.valid === false)
            valueValidatorEmpty.push('Surname');
        if (this.regForm.form.controls.ruolo.valid === false)
            valueValidatorEmpty.push('Role');
        if (this.regForm.form.controls.organizzazione.valid === false)
            valueValidatorEmpty.push('Organization');
        if (this.regForm.form.controls.email.valid === false)
            valueValidatorEmpty.push('Email');
        if (this.regForm.form.controls.org_citta.valid === false)
            valueValidatorEmpty.push('City');
        if (this.regForm.form.controls.org_paese.valid === false)
            valueValidatorEmpty.push('Country');
        if (this.regForm.form.controls.usernameR.valid === false)
            valueValidatorEmpty.push('Username');
        if (this.regForm.form.controls.passwordR.valid === false)
            valueValidatorEmpty.push('Password');
        if (this.regForm.form.controls.passwordR_conf.valid === false)
            valueValidatorEmpty.push('Password Confirm');


        if (valueValidatorEmpty.length > 0) {
            this.successReg = true;
            this.errorTXT = true;
            let html = '<ul>';

            for (let item of valueValidatorEmpty) {
                html += '<li>' + item + '</li>';
            }

            this.alerTextUserReg = "<strong><i class='fa fa-close'></i> Error!</strong> Some items are empty!!" +
                html +
                '</ul>';

        } else {
            if ((this.modelReg.passwordConf === this.modelReg.password) && (this.modelReg.passwordConf !== ' ' && this.modelReg.password !== ' ' )) {
                console.log('parte richiesta al server');
                this.authenticationService.create(this.modelReg)
                    .subscribe(
                        data => {

                            if (data.success === true) {
                                this.successReg = true;
                                this.errorTXT = false;
                                this.alerTextUserReg = "<strong><i class='fa fa-check'></i> Success!</strong> Registration successful!!";
                            } else {
                                this.successReg = true;
                                this.errorTXT = true;
                                this.alerTextUserReg = "<strong><i class='fa fa-close'></i> Error!</strong> user already exists!!";
                            }
                        },
                        error => {
                            this.alertService.error(error);
                            this.loading = false;
                        });

            } else {
                this.alertService.error('Password Confirm is not equal or Password item is empty');
            }
        }
    }

}
