import { Component, OnInit } from '@angular/core';
import {RestApiService} from "../rest-api.service";
import {AlertService} from "../alert.service";

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.css']
})
export class ForgotPasswordFormComponent implements OnInit {

    model: any;

  constructor(private rest:RestApiService,private alertService: AlertService) {

      this.model = {
          username:''
      }

  }

  ngOnInit() {

  }

  submit(){
    console.log(this.model);

      this.rest.reqNewPassword(this.model).subscribe(res =>{


          if(res.success){
              this.alertService.success(res.message);
          } else {
              this.alertService.error(res.message)
          }

      })
  }

}
