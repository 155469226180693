import {OnInit, Component} from '@angular/core';
import {RestApiService} from '../rest-api.service';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  projectList : any = [];

  card: any;

  constructor(private API: RestApiService) {


  }

  ngOnInit() {
      this.API.getProjectAPI().subscribe(res => {
          this.projectList = res;
      });
  }
}

