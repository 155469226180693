import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RestApiService } from '../rest-api.service';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, AfterViewInit {

    constructor(private RestApiService: RestApiService) { }

    newsHome: any = [];
    img: any = [];
    imgSrc: String = '';

  private static convertToScript(d, e, i): void {

    window['_plm'] = [];
    window['_plm'].push(['_btn', 89387]);
    window['_plm'].push(['_loc','itxx0030']);
    window['_plm'].push(['location', document.location.host ]);

    if(d.getElementById(i)) { return; }
    const px = d.createElement(e);
    px.type = 'text/javascript';
    px.async = true;
    px.id = i;
    px.src = ('https:' === d.location.protocol ? 'https:' : 'http:') + '//widget.twnmm.com/js/btn/pelm.js?orig=en_ca';
    const s = d.getElementsByTagName('script')[0];

    const py = d.createElement('link');
    py.rel = 'stylesheet';
    py.href = ('https:' === d.location.protocol ? 'https:' : 'http:') + '//widget.twnmm.com/styles/btn/styles.css';

    s.parentNode.insertBefore(px, s);
    s.parentNode.insertBefore(py, s);

  }

    ngOnInit() {
        this.RestApiService.getNewshome().subscribe(newsHome => {

            for (const obj of newsHome) {
                const newsDate = obj.data.toString();
                const newDate = new Date(newsDate);

                const newsObj = {
                    data: newDate.getDate() + '-' +createMonthName(newDate.getMonth())  + '-' + newDate.getFullYear(),
                    testo: obj.testo
                };

                this.newsHome.push(newsObj);
            }
        });

        this.RestApiService.getScreenImg().subscribe(img => {
            this.img = img;
            this.imgSrc = this.img.immagine;
        });
    }


  ngAfterViewInit() {
    HomeComponent.convertToScript(document, 'script', 'plmxbtn');
  }



}

function createMonthName(mont) { // elaboro i dati per tutte le news
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    return monthNames[mont];
}


