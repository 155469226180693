import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {RestApiService} from './rest-api.service';
import {HomeComponent} from './home/home.component';
import {ResearchComponent} from './research/research.component';
import {AllNewsComponent} from './all-news/all-news.component';
import {ScrollToModule} from 'ng2-scroll-to';
import {NavbarComponent} from './navbar/navbar.component';
import {THomeComponent} from './t-home/t-home.component';
import {TVideoComponent} from './t-video/t-video.component';
import {TPublicationsComponent} from './t-publications/t-publications.component';
import {ProjectsComponent} from './projects/projects.component';
import {StaffComponent} from './staff/staff.component';
import {PublicationsComponent} from './publications/publications.component';
import {PubSearchFormComponent} from './pub-search-form/pub-search-form.component';
import {PublicResultComponent} from './public-result/public-result.component';
import {NguiAutoCompleteModule} from '@ngui/auto-complete';
import {VideoComponent} from './video/video.component';
import {JoinusComponent} from './joinus/joinus.component';
import {JobComponent} from './job/job.component';
import {UserTestComponent} from './user-test/user-test.component';
import {DottoratoComponent} from './dottorato/dottorato.component';
import {TesiComponent} from './tesi/tesi.component';
import {VisitorsComponent} from './visitors/visitors.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {MenuItemToolService} from './menu-item-tool.service';
import {ToolsMenuComponent} from './tools-menu/tools-menu.component';
import {TDownloadComponent} from './t-download/t-download.component';
import {TChangesComponent} from './t-changes/t-changes.component';
import {TExternalComponent} from './t-external/t-external.component';
import {LoginComponent} from './login/login.component';
import {AuthGuardsComponent} from './_guards/auth-guards/auth-guards.component';
import {AlertComponent} from './alert/alert.component';
import {AlertService} from './alert.service';
import {AuthenticateService} from './authenticate.service';
import {DownloadFileComponent} from './download-file/download-file.component';
import {EducationComponent} from './education/education.component';
import {TagInputModule} from 'ngx-chips';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component'; // this is needed!
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module

// Define the routes
const ROUTES = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'Home' }
  },
  {
    path: 'news',
    component: AllNewsComponent,
    data: { title: 'News' }
  },
  {
    path: 'research',
    component: ResearchComponent,
    data: { title: 'Research' }
  },
  {
    path: 'research/:tool/home',
    component: THomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'research/:tool/video',
    component: TVideoComponent
  },
  {
    path: 'research/:tool/publications',
    component: TPublicationsComponent
  },
  {
    path: 'research/:tool/download',
    component: TDownloadComponent,
  },
  {
    path: 'research/:tool/home/download',
    component: LoginComponent,
    canActivate: [AuthGuardsComponent]
  },
  {
    path:'login/resetpassword',
    component: ForgotPasswordFormComponent
  },
  {
    path:'setnewpassword',
    component: ResetPasswordPageComponent
  },
  {
    path: 'research/:tool/:id/download/list/file',
    component: DownloadFileComponent,
    canActivate: [AuthGuardsComponent]
  },
  {
    path: 'research/:tool/changes',
    component: TChangesComponent
  },
  {
    path: 'research/:tool/external',
    component: TExternalComponent
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    data: { title: 'Projects' }
  },
  {
    path: 'people',
    component: StaffComponent,
    data: { title: 'People' }
  },
  {
    path: 'publications',
    component: PublicationsComponent,
    data: { title: 'Publications' }
  },
  {
    path: 'publications-result/:authors',
    component: PublicResultComponent
  },
  {
    path: 'videos',
    component: VideoComponent,
    data: { title: 'Videos' }
  },
  {
    path: 'education',
    component: EducationComponent,
    data: { title: 'Education' }
  },
  {
    path: 'joinus',
    component: JoinusComponent,
    data: { title: 'Joinus' },
    children: [
      {
        path: 'job',
        component: JobComponent
      },
      {
        path: 'user_test',
        component: UserTestComponent
      },
      {
        path: 'dottorato',
        component: DottoratoComponent
      },
      {
        path: 'tesi',
        component: TesiComponent
      }
    ]
  },
  {
    path: 'visitors',
    component: VisitorsComponent,
    data: { title: 'Visitors' },
  }
];


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ResearchComponent,
    AllNewsComponent,
    NavbarComponent,
    THomeComponent,
    TVideoComponent,
    TPublicationsComponent,
    ProjectsComponent,
    StaffComponent,
    PublicationsComponent,
    PubSearchFormComponent,
    PublicResultComponent,
    VideoComponent,
    JoinusComponent,
    JobComponent,
    UserTestComponent,
    DottoratoComponent,
    TesiComponent,
    TesiComponent,
    VisitorsComponent,
    BreadcrumbComponent,
    ToolsMenuComponent,
    TDownloadComponent,
    TChangesComponent,
    TExternalComponent,
    LoginComponent,
    AuthGuardsComponent,
    AlertComponent,
    DownloadFileComponent,
    EducationComponent,
    ForgotPasswordFormComponent,
    ResetPasswordPageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES),
    ScrollToModule.forRoot(),
    NguiAutoCompleteModule,
    TagInputModule,
    NgxPaginationModule,
    BrowserAnimationsModule
  ],
  providers: [
    RestApiService,
    ResearchComponent,
    PubSearchFormComponent,
    MenuItemToolService,
    AlertService,
    AuthGuardsComponent,
    AuthenticateService,
    THomeComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
