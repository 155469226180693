import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {
  linkCorso = 'http://giove.isti.cnr.it/~fabio/corso-hci.html';
  show:boolean = false;
  showNavigation: boolean = true;

  constructor() { }

  ngOnInit() {
      this.show = false;
      this.showNavigation = true;
  }

    showSection(){
      this.show = true;
        this.showNavigation = false;


    }

}
