import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from "@angular/router";
import {filter} from 'rxjs/internal/operators';

interface IBreadcrumb {
    label: any;
    params: Params;
    url:  string;
}


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
    public breadcrumbs: IBreadcrumb[];

    url: string="";
    new_breadcrumbs: any = [];
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.breadcrumbs = [];
        this.new_breadcrumbs = [];
    }

    ngOnInit() {
        const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";

        //subscribe to the NavigationEnd event


            //set breadcrumbs
            let root = this.activatedRoute.root;

            let children: ActivatedRoute[] = root.children;



        //return if there are no more children
        if (children.length === 0) {
            return this.breadcrumbs ;
        }

        for (let child of children) {

            let routerLink = child.snapshot.url.map(segment => segment.path).join('/');
            let label = child.snapshot.url.map(segment => segment.path);

            this.url += `/${routerLink}`;



            console.log(this.url);
            let breadcrumb: IBreadcrumb = {
                label: label,
                params: child.snapshot.params,
                url:  this.url
            };

            let url = '';
            for(let i = 0; i < label.length; i++){

                url += '/'+label[i];
                let breadcrumb: IBreadcrumb = {
                    label: label[i],
                    params: child.snapshot.params,
                    url:  url
                };


                this.new_breadcrumbs.push(breadcrumb);

            }

        }

    }

}
