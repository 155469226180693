import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import {confUrl} from '../config';
import { HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable()
export class RestApiService {

    objPub: any;
    pubResult: any;


    constructor(private http: HttpClient) {
        environment.url = confUrl.url; // in caso di deploy in apache
    }

    getScreenImg(): Observable<any>{
        return this.http.get(environment.url + '/api/getHomeImg');
    }

    getNewshome(): Observable<any> {
        return this.http.get(environment.url + '/api/getNews/homeNews');

    }

    getAllNews(): Observable<any> {
        return this.http.get(environment.url + '/api/getNews/all');
    }

    getAllResearchPost(): Observable<any> {
        return this.http.get(environment.url + '/route/research');
    }

    getPastResearchTool(): Observable<any> {
        return this.http.get(environment.url + '/route/past_research');
    }

    getToolHome(tool: String): Observable<any> {
        return this.http.get(environment.url + '/api/research/' + tool + '/home');
    }

    getToolHomeDownloadCount(tool): Observable<any> {
       // console.log(tool);
        return this.http.get(environment.url + '/api/research/' + tool + '/count_download');
    }
    getToolUserRegist(tool): Observable<any>  {
        return this.http.get(environment.url + '/api/research/'+tool+'/registered_users');
    }

    getTVideo(tool: String): Observable<any>  {
        return this.http.get(environment.url + '/api/research/'+tool+'/video');
    }


    getMenuItem(tool: string): Observable<any>  {
        return this.http.get(environment.url + '/api/research/'+tool+'/menu_items');
    }


    getTPublications(tool :String): Observable<any>  {
        return this.http.get(environment.url + '/api/research/'+tool+'/publications');
    }

    getProjectAPI(): Observable<any>  {
        return this.http.get(environment.url + '/route/projects');
    }

    getStaffAPI(): Observable<any>  {
        return this.http.get(environment.url + '/api/team');
    }

    getPublicationsAPI(): Observable<any>  {
        return this.http.get(environment.url + '/api/publications');
    }

    getPublicationsAuthor1API(): Observable<any>  {
        return this.http.get(environment.url + '/api/publications/getNameAuthor1');
    }

    getVideos(): Observable<any>  {
        return this.http.get(environment.url + '/api/videos/all');
    }

    objPubSAve(obj: any) {
        try {

        this.objPub = obj;

        } catch (e) {
            console.log(e);
        }
    }

    setPubresult(obj: any){
        try{

            this.pubResult = obj;

        }catch (e){
            console.log(e);
        }
    }

    pushDownloadInfo(obj: any): Observable<any>  {
        return this.http.post(environment.url + '/api/download/info', obj, httpOptions);
        //return this.http.post<any>(environment.url + '/api/download/info', obj, options);
    }


    postPublicationsSearch(obj): Observable<any>{
        return this.http.post(environment.url + '/api/publications/search', obj, httpOptions)
    }

    getdownloadFileList(id): Observable<any> {
        return this.http.get(environment.url + '/api/get/file/' + id + '/list');
    }

    getExternalInfo(tool, id): Observable<any>  {
        return this.http.get(environment.url + '/api/' + tool + '/' + id + '/external');
    }

    getDottoratoNews(): Observable<any> {
        return this.http.get(environment.url + '/api/dottorato');
    }

    reqNewPassword(obj: any): Observable<any> {
        return this.http.get(environment.url + '/api/reset/password/' + obj.username);
    }

    confirmToken(token: any) {
        return this.http.get(environment.url + '/api/verify/' + token);
    }

    newPassword(obj): Observable<any> {
        return this.http.post(environment.url + '/api/newpassword', obj, httpOptions);
    }

}
