
import { Component, Injectable, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import { RestApiService } from '../rest-api.service';

@Injectable()
@Component({
  selector: 'app-pub-search-form',
  templateUrl: './pub-search-form.component.html',
  styleUrls: ['./custom.component.scss'],
})
export class PubSearchFormComponent  {

   @Input() dataSearchResp:any;
    public autoAuth1:any [];
    d = new Date();
    public item: any = [];
    public model: any = {
        authors:[],
        title: '',
        luogo:'',
        fromYear: 1988,
        toYear: this.d.getFullYear()
    };

    inputNotToken: string;

    submitted = false;

    @Output() searchresultEmit: EventEmitter<any> = new EventEmitter();


    constructor(private rest: RestApiService, private router: Router) {
        this.rest.getPublicationsAuthor1API().subscribe(res =>{ //form autore1 autocomplete
            this.autoAuth1 = res;
        });
    }

    onSubmit() {

       this.submitted = true;

       this.rest.objPubSAve(this.model);

        localStorage.setItem("objSearch", JSON.stringify(this.model));

        if(this.model.authors.length === 0){
            this.model.authors.push({display: this.inputNotToken, value: this.inputNotToken});
        }

        this.rest.postPublicationsSearch(this.model)
            .subscribe(res => {

                this.dataSearchResp = res;

                this.rest.setPubresult(this.dataSearchResp);
                   this.searchresultEmit.emit(this.dataSearchResp);

                   let query = JSON.stringify(this.model);
                   this.router.navigate(['publications-result','data',  {data : query} ]);

            });

    }

    onTextChange(event){
       this.inputNotToken = event
    }

    active = true;

    default_(): void {
        this.model = [];
    }

}

