import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { RestApiService } from '../rest-api.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {
    @Input() dataSearchResp:any;
    @Output() searchresultEmit: EventEmitter<any> = new EventEmitter();

    staffList: any = [];
    d = new Date();
    public model: any = {
        authors:[],
        title: '',
        luogo:'',
        fromYear: 1988,
        toYear: this.d.getFullYear()
    };

  constructor(private API: RestApiService, private router: Router) { }

  ngOnInit() {

      this.API.getStaffAPI().subscribe(res => {
          this.staffList = res;


      });

  }

    goToPub(cognome) {

        this.model.authors.push({display:cognome, value: cognome});

        this.API.objPubSAve(this.model);

        localStorage.setItem('objSearch', JSON.stringify(this.model));

        if(this.model.authors.length === 0){

            this.model.authors.push({display:cognome, value: cognome});
        }

        this.API.postPublicationsSearch(this.model)
            .subscribe((res: any) => {
              console.log(res)
                this.dataSearchResp = res;

                this.API.setPubresult(this.dataSearchResp);
                this.searchresultEmit.emit(this.dataSearchResp);
                const query = JSON.stringify(this.model);
                this.router.navigate(['publications-result','data',  {data : query} ]);
            });
    }

  personalSite(el) {
    console.log(el);
  }

}
