
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute  } from '@angular/router';
import { PubSearchFormComponent } from '../pub-search-form/pub-search-form.component';
import { Input } from '@angular/core';
import { RestApiService } from '../rest-api.service';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-public-result',
  templateUrl: './public-result.component.html',
  styleUrls: ['./public-result.component.css'],
    providers:[PubSearchFormComponent]
})
export class PublicResultComponent implements OnInit {

   @Input() resultSearch:any ;
   public objPub : any;
   public keys :any = [];
   public paramsUrl: string;
   public dataUrl:any;


  constructor(private route:ActivatedRoute,public service: RestApiService) {}

  ngOnInit() {

      this.dataUrl = decodeURI(this.route.snapshot.params['data']);

      console.log(this.dataUrl);


      if(this.dataUrl !== 'undefined'){
              this.service.postPublicationsSearch(this.dataUrl).subscribe(res => {

                          this.service.setPubresult(res);

                          localStorage.setItem('objSearch', this.dataUrl);
                          localStorage.setItem('pubResult', res);
                      });
      }

      this.resultSearch = this.service.pubResult; // prendo i dati del risultato della ricerca dal servizio

      if(this.resultSearch === undefined) {
          this.resultSearch = JSON.parse(localStorage.getItem('pubResult'));
      }

      if(this.objPub === undefined) {
          this.objPub = JSON.parse(localStorage.getItem('objSearch'));
      }else{
          this.objPub =  this.service.objPub; // dati modello form dal servizio
      }

      if( this.objPub === null ){

          this.objPub =  JSON.parse(this.dataUrl);

          const data = [];
          this.service.postPublicationsSearch(this.objPub).subscribe(res => {
              this.service.setPubresult(res);
              localStorage.setItem("objSearch", this.dataUrl);
              localStorage.setItem("pubResult", res);

              data.push(JSON.parse( res.text()));
              this.resultSearch = JSON.parse( res);

              for(let key in this.resultSearch){
                  this.keys.push({
                      key: key,
                      value: this.resultSearch[key]
                  });
              }

              this.keys.sort(function (name1, name2) {
                  if (name1.key > name2.key) {
                      return -1;
                  } else if (name1.key < name2.key) {
                      return 1;
                  } else {
                      return 0;
                  }
              });
          });

          this.resultSearch = data[0];

      }

      for(let key in this.resultSearch){
          this.keys.push({key: key, value: this.resultSearch[key] });
      }

      this.keys.sort(function (name1, name2) {
          if (name1.key > name2.key) {
              return -1;
          } else if (name1.key < name2.key) {
              return 1;
          } else {
              return 0;
          }
      });

      this.paramsUrl = decodeURI(this.route.snapshot.params['authors']);
      if(this.paramsUrl !== undefined){
           this.service.postPublicationsSearch(this.dataUrl)
              .subscribe(
                  res => {
                      //this.objPub = JSON.parse(res.text());
                      //this.service.setPubresult(JSON.parse(res.text()));
                  });
      }


      console.log(this.resultSearch);
  }

    getNewSearch(obj:any){

        this.resultSearch = obj;
        this.service.setPubresult(obj);
        this.keys = [];
        localStorage.setItem("pubResult", JSON.stringify(obj));

        if(this.resultSearch === undefined){
            this.resultSearch = JSON.parse(localStorage.getItem('pubResult'));// prendo i dati risultato dal localstorage
            this.service.setPubresult(obj);
        }

        this.objPub =  this.service.objPub; //dati modello form dal servizio

        for(let key in this.resultSearch){
            this.keys.push({key: key, value: this.resultSearch[key] });
        }

        this.keys.sort(function (name1, name2) {
            if (name1.key > name2.key) {
                return -1;
            } else if (name1.key < name2.key) {
                return 1;
            } else {
                return 0;
            }
        });
    }

}
