import {Router, ActivatedRoute, Params} from '@angular/router';
import { Injectable } from '@angular/core';
import {OnInit, Component, Input, Output, EventEmitter} from '@angular/core';
import {RestApiService} from '../rest-api.service';
import { MenuItemToolService } from '../menu-item-tool.service';


@Injectable()
@Component({
    selector: 'app-t-home',
    templateUrl: './t-home.component.html',
    styleUrls: ['./t-home.component.css']
})
export class THomeComponent implements OnInit {

    tool: String = '';
    part: string = '';
    homeInfo: any = [];
    listTitle: any = [];
    downloadNumber: number;
    userReg: number;


    private keepAfterNavigationChange = false;


    constructor(private activatedRoute: ActivatedRoute, private RestApiService: RestApiService, private menuService : MenuItemToolService) {


        this.activatedRoute.params.subscribe((params: Params) => {
            this.tool = params['tool'];
            this.part = params['section'];
        });


    }


    ngOnInit() {
            this.RestApiService.getToolHome(this.tool).subscribe(res => {
                this.homeInfo = res[0];

                this.menuService.getToolId(res[0].tool);

                this.RestApiService.getToolHomeDownloadCount(this.homeInfo.tool).subscribe(res =>{

                    this.downloadNumber = res;
                });

                this.RestApiService.getToolUserRegist(this.homeInfo.tool).subscribe(res =>{

                    this.userReg = res;
                });
            });


    }



    refresh(): void {
        window.location.reload();
    }
}
