import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute,Params } from '@angular/router';
import {RestApiService} from "../rest-api.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    tool: String = '';
    part: string = '';
    toolId: any;
    currentUser:any;

    model: any = {
        otherPlatform:'',
        registrato : '',
        piattaforma: '',
        scoporesearch : 0,
        scopoapp: 0,
        teaching: 0,
        scopoother: 0,
        mailinglist: 0,
        email: ''

    };

  constructor( private route: ActivatedRoute,
               private router: Router,
               private RestApiService : RestApiService) {

      this.route.params.subscribe((params: Params) => {
          this.tool = params['tool'];
          this.part = params['section'];
      });


      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

  }

  ngOnInit() {

      this.RestApiService.getToolHome(this.tool).subscribe(res => {
          this.toolId = res[0].tool;
      });
  }


    submitDownloadInfo(){
      console.log('click');
      this.model.registrato = this.currentUser.id;
      this.model['tool'] =  this.tool;
      this.model['toolId'] =  this.toolId;

      switch(this.model.scopo) {
          case 'scoporesearch':
              this.model.scoporesearch = 1;
              delete this.model.scopo;

              break;

          case 'scopoapp':
              this.model.scopoapp = 1;
              delete this.model.scopo;

              break;

          case 'teaching' :
              this.model.teaching = 1;
              delete this.model.scopo;

              break;

          case 'scopoother':
              this.model.scopoother = 1;
              delete this.model.scopo;
      }


        this.RestApiService.pushDownloadInfo(this.model).subscribe( data =>{

                this.router.navigate(['research/' + this.tool +'/'+ this.toolId + '/download/list/file']);
        },
            error => {
                console.log(error);
            }

        );
    }
}
