import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { User } from './user';
import {confUrl} from '../config';
import {map} from 'rxjs/internal/operators';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable()
export class AuthenticateService {
    isLogin = false;

    constructor(private http: HttpClient) { }


    login(obj: User) {

        this.isLogin = true;

        return this.http.post(confUrl.url+'/api/login', obj , httpOptions).pipe(map((response: any) => {
          // login successful if there's a jwt token in the response

          const res: any = response;
          const user = res.message;

          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
          }
          return res;
        }));
    }

    create(user: any) {
        return this.http.post(confUrl.url+'/api/user/registration', user, AuthenticateService.jwt())
          .pipe(map((response: any) => response));
    }

    logout() {
        this.isLogin = false;
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }

    private static jwt() {
        // create authorization header with jwt token
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {

          return  {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': 'Bearer ' + currentUser.token
            })
          };


        }
    }
}
