import { Component,OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {ActivationEnd} from '@angular/router';
import { filter, map} from 'rxjs/internal/operators';
import { mergeMap } from 'rxjs/internal/operators';
import {activateRoutes} from '@angular/router/src/operators/activate_routes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {

  title = 'sito-hiis';

  constructor(private router: Router, private titleService: Title ) {

   this.setTitle('Home');
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe((event: any) => {
        if(event.snapshot.data.title) {

          this.setTitle(event.snapshot.data.title);

        }

      });
  }


  public setTitle( newTitle: string) {
    this.titleService.setTitle('HIIS Laboratory | ' + newTitle );
  }


}
