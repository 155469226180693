import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


@Injectable()
export class MenuItemToolService {
    private subject = new Subject<any>();

    menuItems: any;

  constructor() { }

    getToolId(id) {
        this.subject.next({ id: id });
    }

    getIdTool(): Observable<any> {
        return this.subject.asObservable();
    }

    setMenuItem(obj: any){
        this.menuItems = obj;
        this.subject.next({ obj: obj });
    }

    getMenuItem(): Observable<any>{
        return this.subject.asObservable();
    }

}
