import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-joinus',
  templateUrl: './joinus.component.html',
  styleUrls: ['./joinus.component.css']
})
export class JoinusComponent implements OnInit {

    public show = true;

  constructor(private router: Router) {

      this.router.events.subscribe((event:any) => { //controllo url se è joinus mostro menu
          //console.log(event);
          if(event.url === '/joinus') {
              this.show = true;
          }
      });
  }

  ngOnInit() {

  }

  hideMenu(){
      this.show = false;
  }

}
