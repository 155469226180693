import { Component, OnInit } from '@angular/core';
import {RestApiService} from "../rest-api.service";
import { ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.css']
})
export class DownloadFileComponent implements OnInit {
    id;
    toolName;
    listDownloadTool:any;

  constructor(private restApi: RestApiService,
              private activatedRoute: ActivatedRoute,) {

      this.activatedRoute.params.subscribe((params: Params) => {
          this.id = params['id'];
          this.toolName = params['tool'];
      });

  }

  ngOnInit() {
      this.restApi.getdownloadFileList(this.id).subscribe(res =>{
          this.listDownloadTool = res;
      });
  }

    countDownload(tool, id){
      let user = JSON.parse(localStorage.getItem('currentUser'));
      console.log(user, tool, id);
    }

}
