import { Component, OnInit } from '@angular/core';
import { RestApiService } from "../rest-api.service";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: 'app-t-external',
  templateUrl: './t-external.component.html',
  styleUrls: ['./t-external.component.css']
})
export class TExternalComponent implements OnInit {
    tool: string = '';
    part: string = '';
    toolId;

    listExternalInfo:any;

  constructor(private restApi: RestApiService,private route: ActivatedRoute) {
      this.route.params.subscribe((params: Params) => {
          this.tool = params['tool'];
          this.part = params['section'];
      });
  }

  ngOnInit() {

      this.restApi.getToolHome(this.tool).subscribe(res => {
          this.toolId = res[0].tool;

          this.restApi.getExternalInfo(this.tool, this.toolId).subscribe(res =>{
              this.listExternalInfo = res;
          });
      });
  }

}
