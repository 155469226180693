import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-test',
  templateUrl: './user-test.component.html',
  styleUrls: ['./user-test.component.css']
})
export class UserTestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
