import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { MenuItemToolService } from "../menu-item-tool.service";
import { RestApiService } from "../rest-api.service";
import { ChangeDetectorRef } from "@angular/core";
import { AuthenticateService } from "../authenticate.service";



@Component({
  selector: "app-tools-menu",
  templateUrl: "./tools-menu.component.html",
  styleUrls: ["./tools-menu.component.css"]
})
export class ToolsMenuComponent implements OnInit {
  tool: string = "";
  part: string = "";
  menuItems: any;
  listTitle: any;
  menuDownload;
  menuChanges;
  menuExternal;
  menuVideo;
  isUserAuth: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private menuService: MenuItemToolService,
    private rest: RestApiService,
    private Change: ChangeDetectorRef,
    private authService: AuthenticateService
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.tool = params["tool"];
      this.part = params["section"];
    });
  }

  ngOnInit() {
    this.isUserAuth = this.authService.isLogin;

    this.rest.getMenuItem(this.tool).subscribe(res => {
      this.menuItems = res;
      this.menuService.setMenuItem(this.menuItems);
    });

    this.menuService.getMenuItem().subscribe(res => {
      if (res.obj != undefined) {
        this.menuDownload = res.obj.download;
        this.menuChanges = res.obj.changes;
        this.menuExternal = res.obj.external;
        this.menuVideo = res.obj.video;
      }
    });

    this.rest.getAllResearchPost().subscribe(allPosts => {
      this.listTitle = allPosts;

      this.listTitle.sort(function(name1, name2) {
        if (name1.ordine < name2.ordine) {
          return -1;
        } else if (name1.ordine > name2.ordine) {
          return 1;
        } else {
          return 0;
        }
      });
    });
  }
}
