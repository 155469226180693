import { Component, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-auth-guards',
  templateUrl: './auth-guards.component.html',
  styleUrls: ['./auth-guards.component.css']
})
export class AuthGuardsComponent implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['research/:tool/download'], { queryParams: { returnUrl: state.url }});
        return false;
    }

}
