import { Component, OnInit } from '@angular/core';
import { RestApiService}  from '../rest-api.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

    videosTitle: any;
    videoCode: any = [];
    p = 1;

  constructor(private http : RestApiService,private sanitizer: DomSanitizer) {

      this.http.getAllResearchPost().subscribe(allPosts => {
          this.videosTitle = allPosts;

          this.videosTitle.sort(function (name1, name2) {
              if (name1.ordine < name2.ordine) {
                  return -1;
              } else if (name1.ordine > name2.ordine) {
                  return 1;
              } else {
                  return 0;
              }
          });
      });

      this.http.getVideos().subscribe(res =>{

          for(let i =0; i<res.length; i++){
             const videoObj = {
                  titolo: res[i].titolo,
                  codice: this.sanitizer.bypassSecurityTrustHtml( res[i].codice)
              };

              this.videoCode.push(videoObj);
          }

      });
  }

  ngOnInit() {
  }

}
