import { Component, OnInit } from '@angular/core';
import { RestApiService } from "../rest-api.service";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: 'app-t-changes',
  templateUrl: './t-changes.component.html',
  styleUrls: ['./t-changes.component.css']
})
export class TChangesComponent implements OnInit {
    tool: string = '';
    part: string = '';
    toolId;

    listDownloadFile:any;

  constructor(private restApi: RestApiService,private route: ActivatedRoute) {
      this.route.params.subscribe((params: Params) => {
          this.tool = params['tool'];
          this.part = params['section'];
      });

      this.restApi.getToolHome(this.tool).subscribe(res => {
          this.toolId = res[0].tool;

          this.restApi.getdownloadFileList(this.toolId).subscribe(res =>{
              this.listDownloadFile = res;
          });
      });



  }

  ngOnInit() {




  }

}
