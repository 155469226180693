import { Component, OnInit } from '@angular/core';
import {RestApiService} from "../rest-api.service";

@Component({
  selector: 'app-dottorato',
  templateUrl: './dottorato.component.html',
  styleUrls: ['./dottorato.component.css']
})
export class DottoratoComponent implements OnInit {

  public newsDottorato:any = [];

  constructor(public rest: RestApiService) {

  }

  ngOnInit() {
      this.rest.getDottoratoNews().subscribe( data =>{  this.newsDottorato = data; }, error => console.log(error));
  }


}
