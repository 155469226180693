import { Component, OnInit, Injectable, Input} from '@angular/core';
import { RestApiService } from '../rest-api.service';


@Component({
    selector: 'app-research',
    templateUrl: './research.component.html',
    styleUrls: ['./research.component.css'],
})
export class ResearchComponent implements OnInit {

    researchPreviews: any = [];

    pastResearchPrev: any = [];


    constructor(private RestApiService: RestApiService) {}


    ngOnInit() {
        this.RestApiService.getAllResearchPost().subscribe(allPosts => {
            this.researchPreviews = allPosts;

            this.researchPreviews.sort(function (name1, name2) {
                if (name1.ordine < name2.ordine) {
                    return -1;
                } else if (name1.ordine > name2.ordine) {
                    return 1;
                } else {
                    return 0;
                }
            });
        });


        this.RestApiService.getPastResearchTool().subscribe(res =>{
            this.pastResearchPrev = res;

        })



    }

}
