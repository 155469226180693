import {Router, ActivatedRoute, Params} from '@angular/router';
import {OnInit, Component, Input, Output, EventEmitter} from '@angular/core';
import {RestApiService} from '../rest-api.service';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
    selector: 'app-t-video',
    templateUrl: './t-video.component.html',
    styleUrls: ['./t-video.component.css']
})
export class TVideoComponent implements OnInit {

    private tool: String = '';
    homeInfo: any = [];
    tVideo: any = [];
    listTitle: any = [];
    iFtarme: any = [];


    constructor(private activatedRoute: ActivatedRoute, private RestApiService: RestApiService, private sanitizer: DomSanitizer) {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.tool = params['tool'];
        });

    }

    ngOnInit() {

        this.RestApiService.getToolHome(this.tool).subscribe(res => {
            this.homeInfo = res[0];
        });

        this.RestApiService.getTVideo(this.tool).subscribe(res => {
            this.tVideo = res;
            for (let video of res) {
                this.iFtarme.push(this.sanitizer.bypassSecurityTrustHtml(video.codice));
            }
        });

        this.RestApiService.getAllResearchPost().subscribe(allPosts => {
            this.listTitle = allPosts;

            this.listTitle.sort(function (name1, name2) {
                if (name1.ordine < name2.ordine) {
                    return -1;
                } else if (name1.ordine > name2.ordine) {
                    return 1;
                } else {
                    return 0;
                }
            });
        });
    }


    refresh() {
        window.location.reload();
    }

    get getToolname() {
        return this.tool;
    }

    get getToolHomeInfo() {
        let info: any = [];
        this.RestApiService.getToolHome(this.tool).subscribe(res => {
            info.push(res[0]);
        });
        return info;
    }

    get getListTool() { //return list of tools
        let list: any = [];
        this.RestApiService.getAllResearchPost().subscribe(allPosts => {
            list.push(allPosts);
            list.sort(function (name1, name2) {
                if (name1.ordine < name2.ordine) {
                    return -1;
                } else if (name1.ordine > name2.ordine) {
                    return 1;
                } else {
                    return 0;
                }
            });
        });
        return list;
    }
}
