import { Component, OnInit, Directive, Input } from '@angular/core';
import { RestApiService } from '../rest-api.service';




@Component({
  selector: 'app-all-news',
  templateUrl: './all-news.component.html'
})
export class AllNewsComponent implements OnInit {


    allNews: any = [];
    keys: any = [];


  constructor(private RestApiService: RestApiService) { }


  ngOnInit() {
    this.RestApiService.getAllNews().subscribe(allNews => {
      this.allNews = allNews;
        for (let key in allNews) {
            this.keys.push({key: key, value: allNews[key] });
        }
    });


  }


}

