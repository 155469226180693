import { ActivatedRoute } from '@angular/router';
import { OnInit, Component } from '@angular/core';
import { RestApiService}  from '../rest-api.service';
import { TVideoComponent } from '../t-video/t-video.component';

@Component({
  selector: 'app-t-publications',
  templateUrl: './t-publications.component.html',
  styleUrls: ['./t-publications.component.css'],
    providers:[RestApiService,TVideoComponent],
})
export class TPublicationsComponent implements OnInit {

    tool : any= '';
    homeInfo: any = [];
    listTitle: any = [];
    tPublications: any = [];

  constructor(private RestApiService: RestApiService,private  ActivatedRoute: ActivatedRoute, private TVideo: TVideoComponent) {}

  ngOnInit() {
      this.tool = this.TVideo.getToolname;
      this.homeInfo = this.TVideo.getToolHomeInfo;
      this.listTitle = this.TVideo.getListTool;


      this.RestApiService.getTPublications(this.tool).subscribe(res => { // get publications for specific tool
          this.tPublications = res;
      });
  }
}
