import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {confUrl} from '../../config';
import { map, filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit {
    job: any;

    constructor(private http: HttpClient) { }

  ngOnInit() {
      this.getJSON().subscribe(data =>{ this.job = data}, error => console.log(error));
  }

    public getJSON(): Observable<any> {
        return this.http.get(confUrl.url+ '/route/job').pipe(
            map((res: any) => res  )
    );

    }

}
